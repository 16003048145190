import "./App.css";
import React from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Container,
  Grid,
  Box,
  Divider,
  Avatar,
  Link,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ProductCard } from "./components/ProductCard";

const logo = "/mirobytes.png";
const logoShort = "/logo.png";
const instagramIcon = "/instagram.png";
const facebookIcon = "/facebook.png";
const youtubeIcon = "/youtube.png";
const linkedinIcon = "/linkedin.png";

const OUR_PRODUCTS = [
  {
    title: "Expert Interns",
    description:
      "Are you a college student eager to bridge the gap between academia and industry? Do you aspire to gain the necessary technical skills and secure internships at renowned companies? Look no further than Expert Interns, a groundbreaking platform designed to empower and prepare students for the professional world.",
    link: "#",
    icon: "/expert-interns-logo.png",
  },
  {
    title: "Shopsure",
    description:
      "Your No-Code E-commerce Platform for Effortless Online Selling. Are you a startup or reseller looking to launch your online store without the hassle of dealing with technical complexities? Look no further than Shopsure, the ultimate no-code e-commerce platform designed to empower entrepreneurs like you to effortlessly sell your products online. With Shopsure, you can focus on what matters most: growing your business and delighting your customers.",
    link: "#",
    icon: "/shopsure-logo.png",
  },
];

const useStyles = makeStyles((theme) => ({
  logo: {
    height: theme.spacing(6),
    width: "auto",
  },
  logoShort: {
    height: theme.spacing(20),
    width: "auto",
  },
  content: {
    marginTop: `${theme.mixins.toolbar.minHeight} 0 0 0 !important`,
    paddingLeft: "0 !important",
    paddingRight: "0 !important",
  },
  banner: {
    paddingTop: theme.spacing(15),
    paddingBottom: theme.spacing(15),
    textAlign: "center",
    margin: "auto",
  },
  achievements: {
    paddingTop: theme.spacing(7),
    paddingBottom: theme.spacing(7),
    textAlign: "center",
    margin: "auto",
  },
  header: {
    fontSize: `${theme.spacing(7)} !important`,
    fontWeight: `${theme.typography.fontWeightBold} !important`,
    marginTop: theme.spacing(5),
  },
  odd: {
    backgroundColor: "#9BD23C",
  },
  heading: {
    fontSize: `${theme.spacing(3)} !important`,
    fontWeight: `${theme.typography.fontWeightBold} !important`,
  },
  socialIcon: {
    height: theme.spacing(4),
    width: "auto",
  },
}));

function App() {
  const classes = useStyles();

  return (
    <React.Fragment>
      <AppBar elevation={0} color="transparent" app>
        <Toolbar>
          <img className={classes.logo} src={logo} alt="Mirobytes Logo" />
        </Toolbar>
      </AppBar>
      <Container classes={{ root: classes.content }} maxWidth="xl" fluid>
        <Grid container spacing={3}>
          <Grid xs={12} item>
            <Box className={classes.banner}>
              <img
                className={classes.logoShort}
                src={logoShort}
                alt="Mirobytes Logo"
              />
              <Typography
                component="div"
                variant="h2"
                className={classes.header}
                gutterBottom
              >
                WE DRIVE VISION INTO REALITY
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid container className={classes.odd} spacing={3}>
          <Grid xs={12} item>
            <Box className={classes.achievements}>
              <Typography
                component="div"
                className={classes.heading}
                gutterBottom
              >
                OUR PRODUCTS
              </Typography>
            </Box>
          </Grid>
          {OUR_PRODUCTS.map((o, i) => {
            if (i % 2 === 0)
              return (
                <React.Fragment>
                  <Grid xs={6} item p={2}>
                    <ProductCard key={`product-card-${i}`} {...o} />
                  </Grid>
                  <Grid xs={6} item></Grid>
                </React.Fragment>
              );
            else
              return (
                <React.Fragment>
                  <Grid xs={6} item></Grid>
                  <Grid xs={6} item p={2}>
                    <ProductCard key={`product-card-${i}`} {...o} />
                  </Grid>
                </React.Fragment>
              );
          })}
        </Grid>
        <Grid container spacing={3}>
          <Grid xs={12} item>
            <Box py={8} pl={2}>
              <Typography
                component="div"
                className={classes.heading}
                gutterBottom
              >
                CONTACT US
              </Typography>
              <Typography component="div" variant="h5" gutterBottom>
                Head Location: <b>New Delhi, India</b>
              </Typography>
              <Typography component="div" variant="h5" gutterBottom>
                Reach out to us: <b>contact@mirobytes.com</b>
              </Typography>
            </Box>
          </Grid>
          <Grid xs={12} item>
            <Divider />
          </Grid>
        </Grid>
        <Grid container spacing={3} justifyContent="center">
          <Grid xs={6} item>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              py={4}
            >
              <Link href="#">
                <Avatar
                  src={facebookIcon}
                  alt="Facebook Icon"
                  className={classes.socialIcon}
                  variant="square"
                />
              </Link>
              <Link href="#">
                <Avatar
                  src={instagramIcon}
                  alt="Instagram Icon"
                  className={classes.socialIcon}
                  variant="square"
                />
              </Link>
              <Link href="#">
                <Avatar
                  src={youtubeIcon}
                  alt="Youtube Icon"
                  className={classes.socialIcon}
                  variant="square"
                />
              </Link>
              <Link href="#">
                <Avatar
                  src={linkedinIcon}
                  alt="LinkedIn Icon"
                  className={classes.socialIcon}
                  variant="square"
                />
              </Link>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
}

export default App;
