import { Card, CardContent, Typography, Box, Avatar } from "@mui/material";
import React from "react";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: `${theme.spacing(3)} !important`,
    fontWeight: `${theme.typography.fontWeightBold} !important`,
  },
  description: {
    marginTop: theme.spacing(2),
  },
  icon: {
    width: `${theme.spacing(15)} !important`,
    height: `${theme.spacing(15)} !important`,
  },
}));

export const ProductCard = ({ title, description, link, icon }) => {
  const classes = useStyles();

  return (
    <Card>
      <CardContent>
        <Box display="flex">
          <Box
            flex="1"
            justifyContent="center"
            alignItems="center"
            display="flex"
          >
            <Avatar src={icon} className={classes.icon} variant="rounded" />
          </Box>
          <Box flex="2">
            <Typography className={classes.title}>{title}</Typography>
            <Typography className={classes.description} variant="h5">
              {description}
            </Typography>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

ProductCard.defaultProps = {
  title: null,
  description: null,
  link: null,
  icon: null,
};
