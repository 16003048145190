import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {
  createTheme,
  CssBaseline,
  responsiveFontSizes,
  ThemeProvider,
} from "@mui/material";

const theme = createTheme({
  typography: {
    htmlFontSize: 18,
    fontFamily: "Maven Pro, sans-serif",
  },
});

const Main = () => {
  let themeConfig = createTheme(theme);
  themeConfig = responsiveFontSizes(themeConfig);

  return (
    <React.StrictMode>
      <ThemeProvider theme={themeConfig}>
        <CssBaseline />
        <App />
      </ThemeProvider>
    </React.StrictMode>
  );
};

ReactDOM.render(<Main />, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
